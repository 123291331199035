<template>
  <div  class="card">
    <div class="card-body py-5">
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <div class="form-group has-error">
                <label>Amazon Ad Account</label>

                <AmazonProfileSelect :amazonAdAccounts="amazonAdAccounts"
                                     @changed="amazonAccountChangeEvent"
                                     :disabled="loading" />

                <v-errors :errors="errorFor('amazon_profile')" />

                <!--            <select :class="['form-control', {'is-invalid': errorFor('amazon_profile')}]"
                                    :disabled="loading"
                                    @change="amazonAccountChangeEvent"
                                    v-model="form.amazon_profile">
                              <option value="">Select Amazon Profile</option>
                              <option :value="account.profileId"
                                      v-for="account in amazonAdAccounts"
                                      :key="'amazon_account_'+account.profileId">{{ account.accountInfo.name }} ({{ account.currencyCode }})</option>
                            </select>
                            <v-errors :errors="errorFor('ad_account_id')" />-->
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Type</label>
                <select class="form-control form-select"
                        v-model="form.influencer_link_type">
                  <option value="product">Product</option>
                  <option value="store">Store</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row"
               v-if="form.influencer_link_type === 'product'">
            <div class="col-6">
              <select class="form-control"
                      @change="getAmazonProduct"
                      v-model="form.country_code">
                <option v-for="(marketplace, i) in amazonMarketplaces"
                        :value="marketplace.country_code"
                        :key="'marketplace_'+i">{{ marketplace.country }} ({{ marketplace.host }})</option>
              </select>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input :class="['form-control', {'is-invalid': errorFor('ASIN')}]"
                       :disabled="loading"
                       type="text"
                       id="ASIN"
                       @keyup="getAmazonProduct($event)"
                       placeholder="ASIN"
                       v-model="form.asin">
                <v-errors :errors="errorFor('ASIN')" />
              </div>
            </div>
          </div>

          <div class="row"
               v-if="form.influencer_link_type === 'store'">
            <div class="col-12">
              <div class="form-group">
                <input class="form-control"
                       type="text"
                       placeholder="Store URL"
                       v-model="form.store_url">
              </div>
            </div>
          </div>

          <div class="row mb-3" v-if="form.influencer_link_type === 'product'">
            <div class="col-12">
              <label class="mr-2">Recent </label>
              <span :class="'badge mr-2 mb-2 ' + (form.asin === asin.asin ? 'badge-dark' : 'badge-success')"
                    style="cursor: pointer"
                    :title="asin.asin + ' - ' + asin.title"
                    @click="selectAsin(asin.asin)"
                    v-for="(asin, i) in suggestedAsins"
                    :key="'asin_'+i">{{ asin.asin + ' - ' + asinTitle(asin.title) }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 m-auto">
              <div class="form-group">
                <label for="budget">Campaign Name</label>
                <input :class="['form-control', {'is-invalid': errorFor('budget')}]"
                       :disabled="loading"
                       type="text"
                       id="budget"
                       placeholder="Campaign Name"
                       v-model="form.name">
                <v-errors :errors="errorFor('budget')" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="h-100" id="preview-product">
            <product-preview />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 m-auto">
          <div class="form-group">
            <label for="budget">Spend:</label>
            <div class="row">
              <div class="col-md-6">
                <div class="custom-control custom-checkbox checkbox-primary pl-0">
                  <input type="checkbox" class="custom-control-input" id="basicChk2" value="fixed" v-model="form.isFixed">
                  <label class="custom-control-label" for="basicChk2">Fixed Amount</label>
                </div>
                <div class="pl-1">
                  <div class="input-group">
                    <input type="number" :disabled="!form.isFixed" v-model="form.fixedAmount" class="form-control" placeholder="Amount" />
                    <div class="input-group-append">
                      <button class="btn btn-dark dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">{{ form.currency }} </button>
                      <div class="dropdown-menu currency-dropdown-menu">
                        <a class="dropdown-item dropdown-item-sm"
                           v-for="currency in currencies"
                           :key="'currency_'+currency.id"
                           @click="form.currency = currency.code">{{ currency.code }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="custom-control custom-checkbox checkbox-primary pl-0">
                  <input type="checkbox" class="custom-control-input" id="basicChk3" value="percentage" v-model="form.isPercentage">
                  <label class="custom-control-label" for="basicChk3">Percentage of Sales</label>
                </div>
                <div class="pl-1">
                  <input type="number" v-model="form.percentageAmount" :disabled="!form.isPercentage" class="form-control" placeholder="Percentage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered table-sm">
            <thead>
            <tr>
              <th>Influencer Email(s)</th>
              <th class="text-right">
                <a class="text-success"
                   href="#" @click.prevent="form.emails.push('')">+ Add New</a>
              </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(email, i) in form.emails" :key="'email_'+i">
              <td>
                <input type="text"
                       v-model="form.emails[i]"
                       class="form-control form-control-sm">
              </td>
              <td class="text-right">
                <a class="text-danger" @click.prevent="form.emails.splice(i, 1)">
                  <i class="fas fa-trash"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="mr-2">Recents </label>
          <span :class="'badge mr-2 ' + (form.emails.includes(email) ? 'badge-dark' : 'badge-success')"
                style="cursor: pointer"
                @click="addEmailFromSuggest(email)"
                v-for="(email, i) in suggestedEmails"
                :key="'se_'+i">{{ email }}</span>
        </div>
      </div>
      <hr>

      <div class="row">
        <div class="col-12">
          <div class="custom-control custom-checkbox checkbox-primary pl-0">
            <input type="checkbox"
                   class="custom-control-input"
                   id="basicChk4"
                   v-model="form.create_short_url">
            <label class="custom-control-label"
                   for="basicChk4">Create Short URL</label>
          </div>

          <div class="custom-control custom-checkbox checkbox-primary pl-0">
            <input type="checkbox"
                   class="custom-control-input"
                   id="basicChk42"
                   v-if="form.create_short_url"
                   v-model="form.create_product_page">
            <label class="custom-control-label"
                   for="basicChk42">Create Product Page</label>
          </div>
        </div>
      </div>

      <div class="row" v-if="form.create_product_page">
        <div class="col-md-6">
          <input type="text"
                 v-model="form.product_title"
                 placeholder="Product Title"
                 class="form-control form-control-sm">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductPreview from "@/views/create_campaign/google_wizard/ProductPreview.vue";
import AmazonProfileSelect from "@/components/AmazonProfileSelect.vue";

export default {
  components: {ProductPreview, AmazonProfileSelect},
  props: ['formData', 'errors'],
  data() {
    return {
      suggestedEmails: [],
      suggestedAsins: [],
      form: {}
    }
  },
  watch: {
    errors: {
      handler(val) {
        this.allErrors = val;
      },
      deep: true
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  created() {
    this.loadCurrencies();
    this.loadSuggestedEmails();
    this.loadSuggestedAsins();
  },
  mounted() {
    this.initInfluencerEmailsSelect2();

    if (this.settings && this.settings.amazon_profile_id) {
      this.form.amazon_profile = this.settings.amazon_profile_id;
    }
  },
  computed: {
    ...mapGetters({
      amazonProduct: 'googleWizard/getAmazonProduct',
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
      amazonMarketplaces: 'adAccounts/getAmazonMarketplaces',
      features: 'auth/getFeatures',
      settings: 'settings/getSettings',
    }),
    currentComponent() {
      if (this.currentStep === 1) {
        return 'influencer';
      } else {
        return 'influencer-aweber';
      }
    }
  },
  methods: {
    asinTitle(title) {
      return title.slice(0, 10) + (title.length > 10 ? '...' : '');
    },
    addEmailFromSuggest(email) {
      if (!this.form.emails.includes(email)) {
        const emptyInx = this.form.emails.indexOf('');

        if (emptyInx !== -1) {
          this.form.emails.splice(emptyInx, 1);
        }

        this.form.emails.push(email);
      }
    },
    loadSuggestedAsins() {
      this.suggestedAsins = [];

      this.axios.get('/influencer/asins')
          .then(res => {
            this.suggestedAsins = res.data.data;
          })
    },
    loadSuggestedEmails() {
      this.suggestedEmails = [];

      this.axios.get('/influencer/suggest-emails')
          .then(res => {
            this.suggestedEmails = res.data.data;
          })
    },
    initInfluencerEmailsSelect2() {
      let self = this;

      window.$('.influencer_emails').select2({
        tokenSeparators: ',',
        tags: true
      });

      window.$('.influencer_emails').on('change', function () {
        self.form.emails = window.$(".influencer_emails").select2("val");
      });
    },
    amazonAccountChangeEvent(profileId) {
      this.form.amazon_profile = profileId;

      let account = this.amazonAdAccounts.find(a => a.profileId === profileId);

      if (account) {
        this.form.currency = account.currencyCode;
      }
    },
    loadCurrencies() {
      this.axios.get('/currencies')
          .then(res => {
            this.currencies = res.data.data;
          })
    },
    selectAsin(asin) {
      if (this.form.asin !== asin) {
        this.form.asin = asin;
        this.getAmazonProduct();
      }
    },
    getAmazonProduct() {
      if (this.form.asin.length === 10 &&
          (!this.amazonProduct || this.amazonProduct.asin !== this.form.asin.toUpperCase())) {
        this.blockUI('preview-product');

        this.$store.dispatch('googleWizard/getAmazonProduct', {asin: this.form.asin, countryCode: this.form.country_code})
            .catch((e) => {
              if(e.status === 500) {
                this.showFailMsg("This ASIN doesn't exist");
              }
            })
            .finally(() => {
              this.unblockUI('preview-product');
            })
      }
    }
  }
}
</script>