<template>
  <div class="row">
    <div class="col-md-12 m-auto">
      <page-title-component title="Create Influencers/Manual Campaign" />

      <keep-alive>
        <component :formData="form"
                   :errors="allErrors"
                   :is="currentComponent" />
      </keep-alive>

      <div class="row mt-2" v-if="currentStep === 1">
        <div class="col-md-12 text-right">
          <button type="button"
                  v-if="features && features.aweber && settings.aweber_owner_account_id"
                  @click.prevent="currentStep = 2"
                  class="btn btn-info"
                  :disabled="v$.form.$invalid || loading"> Next</button>

          <button type="button"
                  v-else
                  class="btn btn-info"
                  @click="submit(false)"
                  :disabled="v$.form.$invalid || loading"> Create</button>
        </div>
      </div>

      <div class="row mt-2" v-else>
        <div class="col-6">
          <button type="button"
                  @click="currentStep = 1"
                  :disabled="loading"
                  class="btn btn-dark"> Back</button>
        </div>
        <div class="col-md-6 text-right">
          <button type="button"
                  class="btn btn-dark mr-2"
                  @click="submit(false)"
                  :disabled="loading"> Skip</button>

          <button type="button"
                  class="btn btn-info"
                  @click="submit(true)"
                  :disabled="!form.list_ids.length || !form.body_html.length || loading"> Send Mail</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, requiredIf, url} from '@vuelidate/validators'
import PageTitleComponent from "@/components/PageTitleComponent";
import StepOne from './influencer_steps/StepOne.vue'
import StepTwo from './influencer_steps/StepTwo.vue'
import {mapGetters} from "vuex";

export default {
  components: { PageTitleComponent, StepOne, StepTwo },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      loading: false,
      form: {
        amazon_profile: { required },
        asin: {
          required: requiredIf(() => {
            return this.form.influencer_link_type === 'product'
          })
        },
        store_url: {
          url, required: requiredIf(() => {
            return this.form.influencer_link_type === 'store'
          })
        },
        name: { required },
      },
    }
  },
  data() {
    return {
      allErrors: null,
      currentStep: 1,
      currencies: [],
      suggestedEmails: [],
      form: {
        body_html: '',
        country_code: 'US',
        list_ids: [],
        currency: 'USD',
        emails: [''],
        amazon_profile: '',
        create_short_url: true,
        create_product_page: false,
        product_title: '',
        asin: '',
        name: '',
        isFixed: true,
        fixedAmount: '',
        isPercentage: false,
        percentageAmount: '',
        influencer_link_type: 'product',
        store_url: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      features: 'auth/getFeatures'
    }),
    currentComponent() {
      if (this.currentStep === 1) {
        return 'step-one';
      } else {
        return 'step-two';
      }
    }
  },
  created() {
    this.$store.commit('googleWizard/setAmazonProduct', null);
  },
  methods: {
    async submit(sendMail) {
      this.form.send_mail = sendMail;
      this.loading = true;
      this.allErrors = null;

      this.axios.post('/influencer/create-campaign', this.form)
          .then((result) => {
            this.showSuccessMsg('Added Successfully!')

            this.$router.push({ name: 'influencer_details', params: { id: result.data.id } })
          })
          .catch((err) => {
            if (err.response.status !== 422) {
              if ("data" in err.response) {
                let e = JSON.parse(JSON.stringify(JSON.parse(err.response.data.message)));

                if ("details" in e && e.details.length) {
                  this.showFailMsg(e.details[0].errors[0].message);
                } else {
                  this.showFailMsg("Something went wrong!");
                }
              } else {
                this.showFailMsg("Something went wrong!");
              }
            } else
              this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
    },
  }
}
</script>